import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';

export const bookingScheme = {
  pickUp: yup
    .string()
    .trim()
    .required('required')
    .test(
      'duplicatedDropOff',
      'duplicatedPlace',
      (value: any, ctx: any) => value && value !== ctx.parent.dropOff,
    ),
  dropOff: yup
    .string()
    .trim()
    .required('required')
    .test(
      'duplicatedPickUp',
      'duplicatedPlace',
      (value: any, ctx: any) => value && value !== ctx.parent.pickUp,
    ),
  adults: yup
    .string()
    .trim()
    .required('required')
    .test(
      'isPositiveAdults',
      'onlyPositive',
      (value: any) => Number(value) > 0,
    ),
  children: yup
    .string()
    .trim()
    .test(
      'isPositiveChildren',
      'onlyPositive',
      (value: any) => !value || Number(value) >= 0,
    ),
  fullName: yup.string().trim().required('required'),
  dialCode: yup.string(),
  phone: yup
    .string()
    .trim()
    .required('required')
    .test('isValidPhone', 'invalidPhone', (value: any, ctx: any) => {
      const parsedPhone = parsePhoneNumber(value, ctx.parent.dialCode);
      return (
        value &&
        parsedPhone &&
        parsedPhone.isValid() &&
        parsedPhone.country === ctx.parent.dialCode
      );
    }),
  promotionCode: yup.string().trim(),
  description: yup.string().trim(),
};
