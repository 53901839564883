import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import React, { useCallback, useMemo } from 'react';
import Button from '@mui/material/Button';
import Image from 'next/image';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import useStoreApp from '@/store';

interface IProps {
  phone?: string;
  error?: boolean;
  errorText?: string;
  buttonText: string;
  handle?: any;
  sx?: any;
  bookingDone?: any;
  loading?: boolean;
}

const numberRegex = /\d+/g;

export default function ErrorSubmitBar(props: IProps) {
  const { phone, error, errorText, buttonText, handle, sx, bookingDone, loading } = props;

  const { t } = useTranslation('error');
  const { hotline } = useStoreApp(state => state.globalSlice);

  const makeACall = useCallback(() => {
    window.open(`tel:${hotline}`, '_self');
  }, [hotline]);

  const localeErrorText = useMemo(() => {
    const values = errorText?.match(numberRegex);

    if (
      errorText?.includes('Số lượng hành khách vượt quá giới hạn') &&
      !errorText?.includes('trẻ em')
    )
      return (
        <Trans
          i18nKey="error:error.price.1"
          values={{ maxAdults: values && values[0], hotline }}
          components={{
            span1: <span className="hotline" onClick={makeACall} />,
          }}
        />
      );
    else if (
      errorText?.includes('Số lượng hành khách vượt quá giới hạn') &&
      errorText?.includes('trẻ em')
    )
      return (
        <Trans
          i18nKey="error:error.price.2"
          values={{
            maxAdults: values && values[0],
            maxChildren: values && values[1],
            hotline,
          }}
          components={{
            span1: <span className="hotline" onClick={makeACall} />,
          }}
        />
      );
    else if (
      errorText?.includes('Số điện thoại này đã đặt một chuyến trước đó')
    ) {
      return (
        <Trans
          i18nKey="error:error.order.1"
          components={{
            span1: (
              <a
                href={`/public-history/${phone}`}
                style={{
                  color: '#0D6EFD',
                  fontSize: '16px',
                  fontWeight: 800,
                }}
              />
            ),
          }}
        />
      );
    } else if (errorText?.includes('Tài xế này đang có một chuyến trước đó')) {
      return t('error.order.2');
    } else if (
      errorText?.includes('Thông tin hành khách không được để trống')
    ) {
      return t('error.order.3');
    } else if (
      errorText?.includes('Khoảng cách di chuyển của chuyến đi bằng 0')
    ) {
      return t('error.order.4');
    } else if (errorText?.includes('Số người lớn nhập quá')) {
      return (
        <Trans
          i18nKey="error:error.order.5"
          values={{
            max: values && values[0],
            hotline,
          }}
          components={{
            span1: <span className="hotline" onClick={makeACall} />,
          }}
        />
      );
    } else if (errorText?.includes('Tổng số người trên xe quá')) {
      return (
        <Trans
          i18nKey="error:error.order.6"
          values={{
            max: values && values[0],
            hotline,
          }}
          components={{
            span1: <span className="hotline" onClick={makeACall} />,
          }}
        />
      );
    } else return errorText;
  }, [t, errorText]);

  return (
    <StyledBar className={`${Boolean(error) ? 'error' : ''}`} sx={{ ...sx }}>
      {Boolean(error) && (
        <Box>
          <Image
            src="/assets/icons/error.svg"
            alt="error"
            height={16}
            width={16}
          />

          <Typography component="p">{localeErrorText}</Typography>
        </Box>
      )}

      <Button type="submit" variant="contained" onClick={handle} disabled={loading || bookingDone}>
        {buttonText}
      </Button>
    </StyledBar>
  );
}

export const StyledBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',

  '&.error': {
    justifyContent: 'space-between',
    gap: '32px',
  },

  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',

    '& > p': {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 400,
      color: '#EA5455',
    },
  },

  button: {
    width: '171px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',

    '&.error': {
      justifyContent: 'center',
      gap: '2px',
    },

    button: {
      marginBottom: '16px',
      width: '100%',
    },
  },

  'span.hotline': {
    color: '#0D6EFD',
    fontSize: '15px',
    fontWeight: 800,
    cursor: 'pointer',
  },
}));
