import { formatNumber } from '@/utils/handleFormatNumber';
import { Box, Typography, styled } from '@mui/material';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
interface IProps {
  textKm: string;
  textMoney: string;
  km: number | string;
  money: number | string;
  sx?: any;
}
function PaymentBar({ textKm, textMoney, km, money, sx }: IProps) {
  return (
    <StyledBox sx={{ ...sx }}>
      <Box className="payment-item">
        <Typography component="p">{textKm}</Typography>
        <Typography component="p">{`${km} km`}</Typography>
      </Box>
      <Box className="payment-item">
        <Typography component="p">{textMoney}</Typography>
        <Typography component="p">{`${
          typeof money === 'number' ? formatNumber(money) : money
        } VNĐ`}</Typography>
      </Box>
    </StyledBox>
  );
}

export default memo(PaymentBar);

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '16px 24px',
  width: '100%',
  borderRadius: '8px',
  backgroundColor: theme.palette.success.main,
  display: 'flex',
  justifyContent: 'space-between',

  '.payment-item': {
    display: 'flex',
    gap: '12px',

    p: {
      fontSize: '16px',
      lineHeight: '19px',
      color: theme.palette.white.main,
      opacity: 0.7,
    },

    'p:last-child': {
      fontWeight: 500,
      opacity: 1,
    },
  },
}));
