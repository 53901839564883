import { IDropOff, IPickUp } from '@/types/interface';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IPromotion {
  promotionDetailId?: string;
  promotionId?: string;
  promotionName?: string;
  promotionPercent?: number;
  promotionPrice?: number | null;
  discount?: number;
}

interface IDistanceInformation {
  km: number;
  price: number;
}

interface IPhoneData {
  createdAt?: string;
  createdBy?: string | null;
  description?: string | null;
  dialCode?: string;
  email?: string | null;
  fullName?: string;
  id?: string | null;
  isDeleted: boolean;
  orderBooked?: number;
  orderCompleted?: number;
  otp?: string | null;
  phone?: string | null;
  updatedAt?: string;
  updatedBy?: string | null;
  userId?: string | null;
}

interface IComputerPrice {
  money?: number;
  order?: number;
}

const directionsService = { current: null };
const formatDate = 'DD/MM/YYYY';
const formatTime = 'HH:mm';

const validateBookingTime = (time: Moment) => {
  const bookingTime = moment(time, 'HH:mm');
  const startTime = moment('07:00', 'HH:mm');
  const endTime = moment('23:00', 'HH:mm');

  return bookingTime.isBetween(startTime, endTime, null, '[)');
};

export default function useBooking(service: any) {
  const { t } = useTranslation(['validate', 'error']);

  const [pickUpData, setPickUpData] = useState<IPickUp | null>(null);
  const [dropOffData, setDropOffData] = useState<IDropOff | null>(null);
  const [promotion, setPromotion] = useState<null | IPromotion>(null);
  const [errorPromotion, setErrorPromotion] = useState<string>('');
  const [distanceInformation, setDistanceInformation] =
    useState<IDistanceInformation>({
      km: 0,
      price: 0,
    });
  const [isChangedTimer, setIsChangedTimer] = useState<boolean>(false);
  const [date, setDate] = useState<Moment>(moment());
  const [dateErrMsg, setDateErrMsg] = useState<string>('');
  const [time, setTime] = useState<Moment>(moment().add(1, 'minutes'));
  const [timeErrMsg, setTimeErrMsg] = useState<string>('');
  const [successBooking, setSuccessBooking] = useState<boolean>(false);
  const [bookingErrMsg, setBookingErrMsg] = useState('');
  const [errorPrice, setErrorPrice] = useState('');
  const [bookingDone, setBookingDone] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // get real price
  const realMoney = useMemo(() => {
    if (distanceInformation.km === 0 || distanceInformation.price === 0)
      return 0;

    if (promotion?.discount) {
      return distanceInformation.price - promotion.discount;
    } else return distanceInformation.price;
  }, [promotion?.discount, distanceInformation.km, distanceInformation.price]);

  // check date is today or future
  const isCurrentDate = useMemo(() => {
    if (!isChangedTimer) return false;

    if (date) {
      return date.format(formatDate) === moment().format(formatDate);
    } else return false;
  }, [date, isChangedTimer]);

  // set error message for time, date inputs
  const setDateTimeErrMsg = useCallback(
    (validationError: string | null, type: 'date' | 'time') => {
      let text = '';
      if (validationError === 'invalidDate') {
        text = `${type}Invalid`;
      } else if (validationError === 'disablePast') {
        text = `${type}IsOver`;
      }

      type === 'date' ? setDateErrMsg(text) : setTimeErrMsg(text);
    },
    [],
  );

  // call api and save promotion data
  const handlePromotion = useCallback(
    async (value: string) => {
      try {
        const {
          data,
        }: {
          data: IPromotion;
        } = await service.getPromotionDetail(value.trim());

        if (!!data) {
          setErrorPromotion('');
          setPromotion({
            ...data,
            discount: data?.promotionPercent
              ? Number(
                  (
                    distanceInformation.price *
                    (data?.promotionPercent / 100)
                  ).toFixed(2),
                )
              : data?.promotionPrice ?? undefined,
          });
        }
      } catch (error: any) {
        setPromotion(null);
        if (
          error?.response?.data?.message.includes(
            'Mã không tồn tại hoặc đã hết hạn sử dụng',
          )
        ) {
          const msg = t('error:error.promotion.1');
          setErrorPromotion(msg);
        } else setErrorPromotion(error?.response?.data?.message);
      }
    },
    [distanceInformation.price],
  );

  const handlePhoneNumber = useCallback(
    async (
      dialCode: string,
      phone: string,
      setValue: any,
      fullName: string,
    ) => {
      try {
        const { data }: { data: IPhoneData } = await service.getPhoneDetail({
          phone: phone.trim(),
          dialCode,
        });

        if (data && data?.fullName) {
          setValue('fullName', data?.fullName, { shouldValidate: true });
        } else if (!fullName) {
          setValue('fullName', '', { shouldValidate: true });
        }
      } catch (error: any) {
        console.log(error);
      }
    },
    [],
  );

  const doSubmit = useCallback(
    async (body: any, collaboratorId: string | null = null) => {
      setLoading(true);
      setBookingErrMsg('');
      setSuccessBooking(false);

      try {
        let res;
        if (collaboratorId) {
          res = await service.createOrderFromPartnerQR(collaboratorId, body);
        } else {
          res = await service.createBooking(body);
        }
        const { data } = res;
        if (data.result.length > 1) {
          setBookingDone(data?.result);
        } else {
          setBookingDone(data?.result[0]);
        }
        setSuccessBooking(true);
      } catch (error: any) {
        if (error?.response?.data?.message) {
          setBookingErrMsg(error?.response?.data?.message);
        } else setBookingErrMsg(`${t('common:failBooking')}`);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  // check pickUp & dropOff
  const checkPickUpAndDropOff = useCallback(
    (setValue: any, clearErrors: any) => {
      if (!pickUpData || !dropOffData) {
        setDistanceInformation({ km: 0, price: 0 });
      }

      if (
        !!dropOffData &&
        !!pickUpData &&
        pickUpData?.pickUp !== dropOffData?.dropOff &&
        pickUpData?.pickUpAddress !== dropOffData?.dropOffAddress
      ) {
        clearErrors(['pickUp', 'dropOff']);
      }
    },
    [dropOffData, pickUpData],
  );

  const handleDistanceInformation = useCallback(
    async (adults: number, children: number) => {
      if (distanceInformation.km > 0 && adults > 0) {
        try {
          const { data }: { data: IComputerPrice } = await service.computePrice(
            {
              km: distanceInformation.km,
              passengerNormal: adults,
              passengerHalf: children,
              tripType: 'INNER',
            },
          );

          // rehandle promotion code when recalculate trip's cost
          if (!!promotion && !!data && data?.money) {
            setPromotion({
              ...promotion,
              discount: promotion?.promotionPercent
                ? Number(
                    (data?.money * (promotion.promotionPercent / 100)).toFixed(
                      2,
                    ),
                  )
                : promotion?.promotionPrice ?? undefined,
            });
          }

          // save trip's cost
          setDistanceInformation({
            ...distanceInformation,
            price: data?.money ?? 0,
          });

          setErrorPrice('');
        } catch (error: any) {
          setErrorPrice(error?.response?.data?.message);
        }
      }
    },
    [distanceInformation, promotion],
  );

  useEffect(() => {
    // get information the shortest route such as distance (km)
    if (!directionsService.current && (window as any).google) {
      directionsService.current = new (
        window as any
      ).google.maps.DirectionsService();
    }

    if (!directionsService.current) {
      return undefined;
    }

    if (pickUpData?.pickUp && dropOffData?.dropOff) {
      (directionsService.current as any).route(
        {
          origin: JSON.parse(pickUpData?.pickUp),
          destination: JSON.parse(dropOffData?.dropOff),
          travelMode: 'DRIVING',
        },
        (res: any, status: string) => {
          if (status === 'OK') {
            // sorting all routes by its distance
            res.routes.sort((a: any, b: any) =>
              a.legs[0].distance.value > b.legs[0].distance.value ? 1 : -1,
            );

            const km = Number(
              (res.routes[0].legs[0].distance.value / 1000).toFixed(1),
            );

            // save distance
            setDistanceInformation({ ...distanceInformation, km });
          }
        },
      );
    }
  }, [pickUpData?.pickUp, dropOffData?.dropOff]);

  // validate date and time
  useEffect(() => {
    if (isCurrentDate && moment(time).isBefore(moment())) {
      setDateTimeErrMsg('disablePast', 'time');
    } else {
      setDateTimeErrMsg(null, 'time');
    }
  }, [isCurrentDate, time]);

  useEffect(() => {
    if (!validateBookingTime(time)) {
      const errorMess = t('validate:tourTime', {
        fromTime: '7:00',
        toTime: '23:00',
      });
      setTimeErrMsg(errorMess);
      //setTimeErrMsg('timeInvalid');
    }
  }, [time, date]);

  return {
    pickUpData,
    setPickUpData,
    dropOffData,
    setDropOffData,
    promotion,
    setPromotion,
    errorPromotion,
    setErrorPromotion,
    distanceInformation,
    isChangedTimer,
    setIsChangedTimer,
    date,
    setDate,
    dateErrMsg,
    time,
    setTime,
    timeErrMsg,
    successBooking,
    setSuccessBooking,
    bookingErrMsg,
    errorPrice,
    bookingDone,
    loading,
    realMoney,
    isCurrentDate,
    setDateTimeErrMsg,
    handlePromotion,
    handlePhoneNumber,
    doSubmit,
    checkPickUpAndDropOff,
    handleDistanceInformation,
  };
}
