import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

const bookingBox = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  '& > h3': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
  },
};

export const BookingContainer = styled(Box)(({ theme }: any) => ({}));

export const BookingForm = styled('form')(({ theme }: any) => ({
  padding: '32px 24px',
  borderRadius: '16px',
  backgroundColor: theme.palette.white.main,

  '& > h2': {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 700,
    marginBottom: '24px',
  },

  [theme.breakpoints.down('md')]: {
    marginBottom: '24px',

    '& > h2': {
      textAlign: 'center',
    },
  },
}));

export const BookingTripInformation = styled(Box)(
  ({ theme }) =>
    ({
      ...bookingBox,

      '.wrapper-input-button': {
        display: 'flex',
        gap: '12px',

        '& > button': {
          marginTop: '4px',
          height: '40px',
          width: '40px',
          backgroundColor: '#F9FBFE',

          '& > svg': {
            color: theme.palette.black[20],

            '&:hover': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    } as any),
);

export const BookingNumberOfPeople = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '40px',

  '.MuiTextField-root': {
    minWidth: '168px',
  },

  [theme.breakpoints.down('md')]: {
    marginTop: 0,

    '.MuiTextField-root': {
      width: 'auto',
    },
  },

  [theme.breakpoints.down(475)]: {
    flexDirection: 'column',

    '.MuiTextField-root': {
      width: '100%',
    },
  },
}));

export const BookingPersonalInformation = styled(Box)(
  ({ theme }: any) =>
    ({
      ...bookingBox,

      '.name-phone': {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',

        '.MuiTextField-root': {
          minWidth: '168px',
        },

        [theme.breakpoints.down('md')]: {
          '.MuiTextField-root': {
            width: '100%',
          },
        },

        [theme.breakpoints.down(475)]: {
          flexDirection: 'column',

          '.MuiTextField-root': {
            width: '100%',
          },
        },
      },

      '.wrapper-select-input': {
        display: 'flex',
        gap: '12px',
      },
    } as any),
);

export const BookingDateTime = styled(Box)(
  ({ theme }) =>
    ({
      ...bookingBox,
      marginTop: '24px',

      '.date-time': {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '16px',

        '.MuiTextField-root': {
          // width: '168px',
          flex: 1,
        },

        [theme.breakpoints.down('md')]: {
          '.MuiTextField-root': {
            width: 'auto',
          },
        },

        [theme.breakpoints.down(475)]: {
          flexDirection: 'column',

          '.MuiTextField-root': {
            width: '100%',
          },
        },
      },

      '.MuiInputBase-multiline': {
        borderRadius: '8px',
      },
    } as any),
);

export const StyledSelectDialCode = styled(Select)(({ theme }) => ({
  width: '110px',
  height: '48px',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 400,
  outline: 'none',
  borderRadius: '8px',

  '.MuiSvgIcon-root': {
    right: '3px',
  },

  '.MuiSelect-select': {
    minHeight: 'auto !important',
  },
}));
